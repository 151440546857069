export const environment = {
  production: false,
  apiUrl: 'https://api-bo-test.internal.sparbankensyd.se/api',
  rtUrl: 'https://api-rt-test.internal.sparbankensyd.se/api',
  nextGeneration: true,
  domain: 'bo.internal.sparbankensyd.se',
  testMode: true,
  developerMode: false
}

