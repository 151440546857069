{
  "name": "bo-app",
  "version": "2.3.1-716e0f9f.0",
  "scripts": {
    "build": "./scripts/version.sh && ng build",
    "install": "cp ./package.json ./src/assets",
    "lint": "ng lint",
    "ng": "ng",
    "start": "ng serve --ssl --host localhost.sparbanken.me",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix",
    "test": "ng test --watch=false --code-coverage",
    "watch": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.0.5",
    "@angular/cdk": "^19.0.4",
    "@angular/common": "^19.0.5",
    "@angular/compiler": "^19.0.5",
    "@angular/core": "^19.0.5",
    "@angular/forms": "^19.0.5",
    "@angular/material": "^19.0.4",
    "@angular/platform-browser": "^19.0.5",
    "@angular/platform-browser-dynamic": "^19.0.5",
    "@angular/router": "^19.0.5",
    "rxjs": "^7.8.1",
    "tslib": "^2.4.0",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.6",
    "@angular-eslint/builder": "^19.0.2",
    "@angular-eslint/eslint-plugin": "^19.0.2",
    "@angular-eslint/eslint-plugin-template": "^19.0.2",
    "@angular-eslint/schematics": "^19.0.2",
    "@angular-eslint/template-parser": "^19.0.2",
    "@angular/cli": "^19.0.6",
    "@angular/compiler-cli": "^19.0.5",
    "@angular/material-luxon-adapter": "^19.0.4",
    "@sparbanken-syd/borgo-helpers": "^1.3.0",
    "@sparbanken-syd/personnummer": "^3.0.0",
    "@sparbanken-syd/sparbanken-syd-theme": "^19.0.0",
    "@types/jasmine": "^4.0.3",
    "@types/jmespath": "^0",
    "@types/karma-spec-reporter": "^0",
    "@types/luxon": "^3.4.2",
    "@types/node": "22",
    "@types/sparbanken-syd-borgo": "^3.0.8",
    "@typescript-eslint/eslint-plugin": "^6.10.0",
    "@typescript-eslint/parser": "^6.10.0",
    "@typescript-eslint/types": "^8.18.2",
    "@typescript-eslint/utils": "^8.18.2",
    "eslint": "^8.53.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-jsdoc": "^48.2.5",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "jasmine-core": "^5.1.2",
    "jmespath": "^0.16.0",
    "karma": "^6.4.3",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "^2.2.1",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "karma-spec-reporter": "^0.0.36",
    "luxon": "^3.5.0",
    "postcss": "^8.4.49",
    "postcss-scss": "^4.0.9",
    "stylelint": "^16.12.0",
    "stylelint-config-standard-scss": "^14.0.0",
    "stylelint-scss": "^6.3.0",
    "typescript": "~5.6.3"
  },
  "closed": {
    "start": "2024-11-09T23:05:00",
    "end": "2024-11-10T07:05:00"
  }
}
